import React, { useMemo, useState } from "react";
import { Card, Tooltip } from "antd";
import classNames from "classnames";
import { differenceInDays, addDays, format } from "date-fns";

import styles from "./styles.module.scss";
import Setting from "@assets/icons/setting";
import { initialChecks } from "./utils";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { WidgetClass } from "@services/widget.service";
import { SalesPerformanceProps } from "@services/sales-performance/sales-performance.types";
import { useSalesPerformanceChart } from "@services/sales-performance/useSalesPerformance";
import { BarChart } from "./components/BarChart";
import { LineChart } from "./components/LineChart";
import { SettingsModal } from "./components/SettingsModal";
import { WIDGET_METRICS_CONFIG } from "@services/math/math.const";
import { useIsMobile } from "src/utils/isMobile";
import { useAdvancedViewStore } from "src/store/advancedView";

interface ChartDataPoint {
  date: string;
  [key: string]: number | string;
}

function mockDataCompareWithOffset(
  mainData: ChartDataPoint[],
  compareStart: Date,
): ChartDataPoint[] {
  return mainData.map((point, i) => {
    const currentDay = addDays(compareStart, i);
    const newDateString = format(currentDay, "MMM dd");
    const newPoint: ChartDataPoint = {};

    Object.keys(point).forEach((key) => {
      if (key === "date") {
        newPoint.date = newDateString;
      } else {
        const originalVal = Number(point[key] ?? 0);
        const randomCoef = 0.6 + Math.random() * 0.8;
        newPoint[key] = +(originalVal * randomCoef).toFixed(2);
      }
    });

    return newPoint;
  });
}

function mergeMainWithCompare(
  main: ChartDataPoint[],
  compare: ChartDataPoint[],
): ChartDataPoint[] {
  return [...main, ...compare];
}

const SalesPerformance: React.FC<SalesPerformanceProps> = ({
  choosedGroups = "all",
  hideBg = false,
}) => {
  const [activeLabel, setActiveLabel] = useState<string | null>(null);

  const { isAdvancedView, setIsAdvancedView } = useAdvancedViewStore();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [checks, setChecks] = useState(initialChecks);
  const [isForecasted, setIsForecasted] = useState(false);

  const isMobile = useIsMobile();
  const { dateRange } = useDashboardStore();

  const compareWith = useMemo(() => {
    if (!isForecasted) return null;
    const mainDays =
      differenceInDays(dateRange.endDate, dateRange.startDate) + 1;
    const compareStart = addDays(dateRange.endDate, 1);
    const compareEnd = addDays(compareStart, mainDays - 1);
    return { startDate: compareStart, endDate: compareEnd };
  }, [isForecasted, dateRange]);

  const { data: mainData, metricsColors } = useSalesPerformanceChart(
    dateRange,
    compareWith,
    checks,
  );

  let finalCompare: ChartDataPoint[] = [];
  if (compareWith) {
    finalCompare = mockDataCompareWithOffset(mainData, compareWith.startDate);
  }

  const mergedData = mergeMainWithCompare(mainData, finalCompare);

  const mainDataLength = mainData.length + finalCompare.length;

  const activeChecks = Object.entries(checks)
    .filter(([_, isChecked]) => isChecked)
    .map(([key]) => key);

  return (
    <Card
      className={classNames(styles.cardPerfomance, {
        [styles.cardPerfomanceBg]: !hideBg,
      })}
      bodyStyle={{ padding: isMobile ? "0" : "5px" }}
    >
      <div className={styles.headerBetween}>
        <h1 className={styles.titleSales}>Sales Performance</h1>
        <button
          className={styles.setting__button}
          onClick={() => setIsOpenModal(true)}
        >
          <Setting />
        </button>
        <SettingsModal
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          isAdvancedView={isAdvancedView}
          handleToggleView={setIsAdvancedView}
          checks={checks}
          setChecks={setChecks}
          isForecasted={isForecasted}
          setIsForecasted={setIsForecasted}
          choosedGroups={choosedGroups}
        />
      </div>

      <div className={styles.legendContainer}>
        {Object.entries(checks).map(
          ([key, isChecked]) =>
            isChecked && (
              <div key={key} className={styles.legendItem}>
                <span
                  className={styles.colorDot}
                  style={{ backgroundColor: metricsColors[key] }}
                />
                <span className={styles.legendText}>
                  {WidgetClass.convertKeyToName(key)}
                </span>
                <Tooltip title={WIDGET_METRICS_CONFIG[key]?.info}>
                  <button>i</button>
                </Tooltip>
              </div>
            ),
        )}
      </div>

      {!isAdvancedView ? (
        <LineChart
          data={mergedData}
          mainDataLength={mainDataLength}
          metrics={activeChecks}
          metricsColors={metricsColors}
          isForecasted={isForecasted}
        />
      ) : (
        <BarChart
          dataSet={mainData}
          activeLabel={activeLabel}
          setActiveLabel={setActiveLabel}
          metrics={activeChecks}
          metricsColors={metricsColors}
          isForecasted={isForecasted}
        />
      )}
    </Card>
  );
};

export default SalesPerformance;
